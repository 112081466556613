// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ADMIN_ROUTE = '/admin';
const PRACTICE_TEST = '/practice-test';
const REFERRAL = '/referral';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  referral: '/referral',
  exams: '/exams',
  exam: '/exams/:name',
  feedback: '/feedback',
  plan: '/plan',
  faqs: '/faqs',
  page404: '/papers',
  page500: '/500',
  components: '/components',
  privacy: '/privacypolicy',
  paymentmethod: '/premium-access',
  terms: '/tos',
  request: '/request',
  redeemreward: '/redeem-reward'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking')
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all')
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path('', '/user'),
    profile: path('', '/user/profile'),
    cards: path('', '/user/cards'),
    list: path('', '/user/list'),
    newUser: path('', '/user/new'),
    editById: path('', `/user/reece-chung/edit`),
    account: path('', '/user/account')
  },
  eCommerce: {
    banners: '/banners',
    root: path('', '/papers'),
    shop: path('', '/papers'),
    product: path(ROOTS_DASHBOARD, '/papers/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/papers/product/nike-air-force-1-ndestrukt'),
    list: path('', '/user/paper-list'),
    buyPaper: path('', '/product/:name/buy'),
    newProduct: path('', '/papers/product/new'),
    editById: path(ROOTS_DASHBOARD, '/papers/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/papers/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/papers/invoice')
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post')
  },
  admin: {
    root: path(ADMIN_ROUTE, ''),
    paperAccess: path(ADMIN_ROUTE, '/paper-access'),
    usersDashboard: path(ADMIN_ROUTE, '/users-dashboard'),
    paperDashboard: path(ADMIN_ROUTE, '/paper-dashboard'),
    transferAccount: path(ADMIN_ROUTE, '/transfer-account'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post'),

    // vouchers
    vouchersDashboard: path(ADMIN_ROUTE, '/vouchers')
  },
  paperTest: {
    root: path(PRACTICE_TEST, ''),
    practiceTest: path(PRACTICE_TEST, ''),
    practiceTestHistory: path(PRACTICE_TEST, '/history')
  },
  referral: {
    root: path(REFERRAL, ''),
    practiceTest: path(REFERRAL, ''),
    practiceTestHistory: path(PRACTICE_TEST, '/history')
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
